import React from 'react';
import Helmet from 'react-helmet';
import OfficesList from './OfficesList';
import '../../Content/css/contact.css';
import {db} from '../../firebase';
import PageHeader from '../PageComponents/PageHeader';
import {Event} from '../Analytics/';
let validator = require('email-validator');
let siteURL = process.env.REACT_APP_APP_URL;
export default class Contact extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			gravContent: this.props.pageContext.frontmatter,
			headContent: this.props.pageContext.head,
			media: {},
			loading: true,
			offices: this.props.pageContext.frontmatter.offices_list,

		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	updateWindowDimensions() {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	componentDidMount(){
		this._isMounted = true;
		if(this._isMounted){
			window.scrollTo(0,0);
	      	this.updateWindowDimensions();
	  		window.addEventListener('resize', this.updateWindowDimensions.bind(this));
	  		this.setState({loading: false})
		}
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	  this._isMounted = false;
	}


	render() {
		let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc,} = this.props.pageContext.head;
		return (
			<main>
				<Helmet>
					<title>Contact | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={shareImg} />
					<meta property="og:image:url" content={shareImg}/>
					<meta name="twitter:image" content={shareImg}/>
					<meta name="twitter:card" content={shareImg}/>
				</Helmet>
				
				<div style={typeof window !== 'undefined'?{position: 'relative', minHeight: window.innerHeight}: null}>
					
					
					<section>
						<div className="max-w-md mx-auto">
						<PageHeader mainHeading={this.state.gravContent.contact_heading} subHeading={this.state.gravContent.map_heading} />
						</div>
							<div className="pageGrid">
								<section>
									<ContactForm/>
								</section>
								<OfficesList offices={this.state.offices}/>
							</div>
					</section>
				</div>
			</main>
		);
	}
}



//
//Contact Form
export class ContactForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			message: '',
			checkValidation: false,
			errorMessage: '',
			confirmation: false,
			fade: false,
			formHeight: '240px',
		}
		this.sendEmail = this.sendEmail.bind(this)
	}
	handleForm() {
		let name = this.state.name;
		let email = this.state.email;
		let message = this.state.message;
		if (email.length === 0 || name.length === 0 || message.length === 0) {
			this.setState({
				checkValidation: true,
				errorMessage: 'All fields are required'
			})

		} else {
			let validEmail = validator.validate(this.state.email)
			if (validEmail === true) {

				this.setState({
					errorMessage: '',
				})
				this.sendEmail();
			} else {
				this.setState({
					errorMessage: 'Email address is badly formatted',
				})
			}
		}
	}
	sendEmail(){

		let date = new Date();
		let timestamp = date.getTime();
		let message = {
			name: this.state.name,
			email: this.state.email,
			message: this.state.message,
			checkValidation: false,

		}
		Event("Enquiries", "Made Contact Enquiry", "/contact");  
		db.storeEmailMessage(timestamp, message)
		this.resetForm();
	}
	resetForm(){
		let formContainer = document.getElementById('formContainer')
		this.setState({
			email: '',
			name: '',
			message: '',
			
			fade: true,
			formHeight: formContainer.offsetHeight,
		})

		setTimeout(()=>{
			this.setState({
				confirmation: true,
				fade: false,
			})
		}, 300);
		setTimeout(()=>{this.setState({fade: true})}, 4600);
		setTimeout(()=>{this.setState({confirmation: false})}, 4900);
		setTimeout(()=>{this.setState({fade: false,})}, 5000);
	}
	handleName({target}){
		this.setState({name: target.value});
	}
	handleEmail({target}){
		this.setState({email: target.value});
	}
	handleMessage({target}){
		this.setState({message: target.value});
	}
	render() {
		let emailValidator = validator.validate(this.state.email)
		
		let nameInvalid = this.state.name.length === 0 && this.state.checkValidation === true;
		let emailInvalid = this.state.email.length === 0 && this.state.checkValidation === true && emailValidator === false;
		let messageInvalid = this.state.message.length === 0 && this.state.checkValidation === true;
		let confirmation = this.state.confirmation === true;
		return (
			<div className="contactFormWrapper">
				{confirmation?
					<div className="messageScreen" data-fade={this.state.fade} style={{height: this.state.formHeight + 'px'}}>
						<div className="messageConfirmationWrapper text-center">
							<span className="flaticon-success mb-3" 
								style={{color: '#72bf44', fontSize: '30px', display: 'inline-block'}}>
							</span>
							<h2>Thank you</h2>
							<p>Your Message has been sent</p>
						</div>
					</div>
				:
					<div className="max-w-md mx-auto" id="formContainer" data-fade={this.state.fade}>
						<div>
							<div className="w-full">
								<div className="inputWrapper">
									<label className="block">
										Name {nameInvalid && <span className="warningText">* Required</span>}
										<input data-invalid={nameInvalid} type="text" onChange={this.handleName.bind(this)} value={this.state.name} className="formControl"/>
									</label>
								</div>
							</div>
							<div className="w-full">
								<div className="inputWrapper">
									<label>Email {emailInvalid && <span className="warningText">* Required</span>}</label>
									<input data-invalid={emailInvalid}
										type="text" 
										onChange={this.handleEmail.bind(this)} 
										value={this.state.email} 
										className="formControl"
									/>
								</div>
							</div>
							<div className="w-full">
								<div className="inputWrapper">
									<label>Message {messageInvalid && <span className="warningText">* Required</span>}</label>
									<textarea onChange={this.handleMessage.bind(this)} 
										value={this.state.message} 
										className="formControl  h-40"
										data-invalid={messageInvalid}
									/>
								</div>
							</div>
							<div className="w-full text-center">
								<span className="warningTab font-medium my-2" >{this.state.errorMessage}</span>
							</div>
							<div className="w-full text-center">
								<div className="mt-2">
									<button onClick={this.handleForm.bind(this)} className="bg-primary py-2 px-6 w-full text-white rounded=lg">Send Message</button>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}
