import React from 'react';
const OfficesList = (props) => {
	let list = props.offices.map((item, key)=>
		<OfficesListTile freePhone={item.free_phone}
			phone={item.phone}
			title={item.title}
			fax={item.fax}
			email={item.office_email}
			flag={item.flag}
			address={item.address}
			key={key}
		/>
	);
	return (
		<ul className="contactOfficeList">{list}</ul>
	)
} 
export default OfficesList;
export const OfficesListTile = (props) => {
	let {title, email, freePhone, phone, address} = props;
		return (
		<li>
			<div>
				{title&&
					<h4 className="title font-medium mb-4">{title}</h4>
				}
				{phone&&
					<p className="text-sm pb-0 mb-1"><strong className="font-medium">Phone:</strong> <a className="text-sm" href={"tel:" + phone}>{phone}</a></p>
				}
				{freePhone&&
					<p className="text-sm pb-0 mb-1"><strong className="font-medium">Free Phone:</strong> <a className="text-sm" href={"tel:" + freePhone}>{freePhone}</a></p>
				}
				{email&&
					<p className="text-sm pb-0 mb-1"><strong className="font-medium">Email</strong>: <a className="text-sm" href={"mailto:" + email}>{email}</a></p>
				}
				{address&&
					<p className="text-sm pb-0 mb-1"><strong className="font-medium">Address</strong>: <span className="text-xs">{address}</span></p>
				}
			</div>
		</li>
	);
}

